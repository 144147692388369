import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import styled from "styled-components";
import "typeface-source-sans-pro";

import { Container } from "unstyled";
import Footer from "../components/Footer";
import Navigation from "./Navigation/Navigation";
import NavigationMobile from "./Navigation/NavigationMobile";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled(Container)`
  flex: 1;
  margin: 0 auto;
  height: 100%;
`;

const TemplateWrapper = ({ children }) => (
  <Wrapper>
    <Helmet
      title="Hilgerduo "
      meta={[
        {
          name: "description",
          content: "Hilgerduo Leipzig"
        },
        {
          name: "keywords",
          content: "duo, hilger, leipzig, violine, violoncello"
        }
      ]}
    />
    {/*<Header />*/}
    <Navigation />
    <Content>{children}</Content>
    <Footer />
    <NavigationMobile />
  </Wrapper>
);

TemplateWrapper.propTypes = {
  children: PropTypes.any
};

export default TemplateWrapper;
