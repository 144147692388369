import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
// import SEO from '../components/SEO'

const Component = ({ data: { markdownRemark: privacy } }) => (
  <Layout>
    {/*<SEO data={{ title: 'Datenschutz' }} />*/}
    <div dangerouslySetInnerHTML={{ __html: privacy.html }} />
  </Layout>
);

export const query = graphql`
  query Privacy {
    markdownRemark(frontmatter: { title: { eq: "privacy" } }) {
      id
      html
    }
  }
`;

export default Component;
