import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import presets from "utils/presets";
import { Container } from "unstyled";

const Component = styled.div`
  display: none;
  border-bottom: 1px solid #f8f8f8;
  margin-bottom: 1rem;
  ${presets.Tablet} {
    display: inherit;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-flow: nowrap row;
  justify-content: space-between;
`;
const Item = styled(Link)`
  margin-right: 1rem;
  text-decoration: none;
  padding: 1rem;
  font-weight: 300;
  font-size: 1.2rem;
`;

const Navigation = () => (
  <Component>
    <Container>
      <Menu>
        <Item to="/">Home</Item>
        <Item to="/diskographie">Diskographie</Item>
        <Item to="/duo">Duo</Item>
        <Item to="/konzerte">Konzerte</Item>
        <Item to="/editionen">Noteneditionen</Item>
        <Item to="/kontakt">Kontakt</Item>
      </Menu>
    </Container>
  </Component>
);

export default Navigation;
