import React from "react";
import { Link as BaseLink } from "gatsby";
import styled from "styled-components";
import { Container } from "unstyled";

const Element = styled.div`
  background-color: hsla(0, 0%, 0%, 0.2);
  padding: 1rem 0;
`;

const Link = styled(BaseLink)`
  color: white;
  text-decoration: none;
  margin-right: 1rem;
`;

const Footer = () => (
  <Element>
    <Container>
      <Link to="/impressum">Impressum</Link>
      <Link to="/datenschutz">Datenschutz</Link>
    </Container>
  </Element>
);

export default Footer;
